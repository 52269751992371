import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Feature, MultiPolygon } from "geojson";

import { fondServiceURL, selectVersionConfig, useGetMultiProjectQuery } from "fond/api";
import { useOverlay } from "fond/hooks/useOverlay";
import SourceAndLayers from "fond/map/SourceAndLayers";
import * as turf from "fond/turf";
import { Store } from "fond/types";
import { LayerConfig, LayerStyle, SublayerConfig } from "fond/types/ProjectLayerConfig";
import { isVisible } from "fond/utils/configurations";

interface IProps {
  layerConfigs: Array<LayerConfig | SublayerConfig>;
  styles: LayerStyle[];
  layerView: {
    [key: string]: boolean;
  };
}

const MapContent: React.FC<IProps> = ({ layerConfigs, layerView, styles }: IProps) => {
  const versionId = useSelector((state: Store) => state.project.versionId);
  const configData = useSelector((state: Store) => selectVersionConfig(state, versionId));
  const { data: project } = useGetMultiProjectQuery(versionId);
  const { overlay, tileUrl, isLoading } = useOverlay("fcc-2024");
  const checkVisibility = (id: string, view: { [layerId: string]: boolean }) => isVisible(configData, { id: id, layerView: view });

  const features = useMemo(() => {
    const polygons: Feature<MultiPolygon>[] = [];

    // Add the city boundary
    if (project?.Boundary)
      polygons.push({
        type: "Feature",
        properties: {
          id: project?.ID,
          boundaryId: project?.ID,
          name: "City Boundary",
        },
        geometry: {
          ...(project?.Boundary || {}),
        },
      });

    // Add the sub area boundaries
    project?.Areas.forEach((area) => {
      polygons.push({
        type: "Feature",
        properties: {
          id: area.ID,
          boundaryId: area.ID,
          name: area.Name,
        },
        geometry: area.Boundary,
      });
    });

    return polygons;
  }, [project]);

  // If we don't add all the SourceAndLayers at the same time then nothing will work, so hold off
  // on rendering it until we've finished loading our overlay information.
  if (isLoading) {
    return null;
  }

  return (
    <>
      {overlay && (
        <SourceAndLayers
          sourceId={overlay.Key}
          source={{
            type: "vector",
            tiles: [tileUrl],
            promoteId: "id",
            minzoom: overlay.TileCollection?.MinZoom,
            maxzoom: overlay.TileCollection?.MaxZoom,
          }}
          styles={styles}
          layers={layerConfigs}
          layerVisibilities={layerView}
          isVisible={checkVisibility}
        />
      )}
      <SourceAndLayers
        sourceId="multiProject-source"
        source={{
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: features,
          },
          promoteId: "id",
        }}
        styles={styles}
        layers={layerConfigs}
        layerVisibilities={layerView}
        isVisible={checkVisibility}
      />
    </>
  );
};

export default MapContent;
