import { ConfigurationUpsert, GroupConfig } from "fond/types";
import { FilterConfiguration, LayerConfig, LayerStyle, SublayerConfig } from "fond/types/ProjectLayerConfig";
import { pickIDs } from "fond/utils";

type Bucket = {
  id: string;
  label: string;
  color: string;
  filter: FilterConfiguration;
};

const fccMaxDownloadBuckets = (): Bucket[] => {
  return [
    {
      id: "fcc-max-download-0-25",
      label: "0 - 25",
      color: "#d53e4f",
      filter: {
        Mapbox: ["all", [">=", ["get", "max_download"], 0], ["<", ["get", "max_download"], 25]],
        Type: "group",
      },
    },
    {
      id: "fcc-max-download-25-100",
      label: "25 - 100",
      color: "#f96a00",
      filter: {
        Mapbox: ["all", [">=", ["get", "max_download"], 25], ["<", ["get", "max_download"], 100]],
        Type: "group",
      },
    },
    {
      id: "fcc-max-download-100-500",
      label: "100 - 500",
      color: "#f5c802",
      filter: {
        Mapbox: ["all", [">=", ["get", "max_download"], 100], ["<", ["get", "max_download"], 500]],
        Type: "group",
      },
    },
    {
      id: "fcc-max-download-500-1000",
      label: "500 - 1000",
      color: "#c9e816",
      filter: {
        Mapbox: ["all", [">=", ["get", "max_download"], 500], ["<", ["get", "max_download"], 1000]],
        Type: "group",
      },
    },
    {
      id: "fcc-max-download-1000+",
      label: "1000+",
      color: "#4dd53e",
      filter: {
        Mapbox: [">=", ["get", "max_download"], 1000],
        Type: "expression",
      },
    },
  ];
};

export const fccMaxDownloadConfigs = (): ConfigurationUpsert => {
  const groupId = "multiProjectFccGroup";

  // Create the fiber footprint layer
  const fiberFootprintLayerId = "fcc-fiber-footprint";
  const fiberFootprintStyles = fccFiberServedStyles(fiberFootprintLayerId, "#4dd53e");
  const fiberFootprintLayerConfig = fccLayerConfigTemplate(fiberFootprintLayerId, groupId, "Fiber Footprint", [...fiberFootprintStyles], []);

  // Create the max download speed layer/sublayers
  const maxDownloadLayerId = "fcc-max-download";
  const buckets = fccMaxDownloadBuckets();
  const maxDownloadLayerConfig = fccLayerConfigTemplate(
    maxDownloadLayerId,
    groupId,
    "Max Download Speed",
    [],
    buckets.map((bucket) => bucket.id)
  );
  const subLayers = buckets.map((bucket) => {
    const styles = fccMaxDownloadStyles(bucket.id, bucket.color, bucket.filter, 0.3);
    const sublayerConfig = fccSublayerTemplate(bucket.id, maxDownloadLayerId, bucket.label, bucket.filter, [...styles]);

    return [...styles, sublayerConfig];
  });

  const [attLayerConfig, ...otherAttConfigs] = serviceTypes("AT&T Coverage", groupId, "att_service_type");
  const [tmobLayerConfig, ...otherTmobConfigs] = serviceTypes("T-Mobile Coverage", groupId, "tmob_service_type");
  const [verizonLayerConfig, ...otherVerizonConfigs] = serviceTypes("Verizon Coverage", groupId, "verizon_service_type");

  const fccLayers = [fiberFootprintLayerConfig, maxDownloadLayerConfig, attLayerConfig, tmobLayerConfig, verizonLayerConfig];
  const fccGroup = groupConfigTemplate(groupId, fccLayers, "Overlays");

  return [...fiberFootprintStyles, ...subLayers.flat(), ...otherAttConfigs, ...otherTmobConfigs, ...otherVerizonConfigs, ...fccLayers, fccGroup];
};

const serviceTypes = (label: string, groupId: string, providerField: string): ConfigurationUpsert => {
  const layerConfigId = `fcc-${providerField}-service-types`;

  const technologies = [
    { id: `${layerConfigId}-Fiber`, technology: "Fiber", color: "#80b1d3" },
    { id: `${layerConfigId}-Cable`, technology: "Cable", color: "#bebada" },
    { id: `${layerConfigId}-Power`, technology: "Power", color: "#b3de69" },
    { id: `${layerConfigId}-Satellite`, technology: "Satellite", color: "#fdb462" },
    { id: `${layerConfigId}-Wireless`, technology: "Wireless", color: "#fb8072" },
    { id: `${layerConfigId}-DSL`, technology: "DSL", color: "#8dd3c7" },
    { id: `${layerConfigId}-Copper`, technology: "Copper", color: "#ffffb3" },
  ];

  const subLayers = technologies.flatMap(({ id, technology, color }) => {
    const filter = {
      Mapbox: ["in", technology, ["get", providerField]],
      Type: "expression",
    } as FilterConfiguration;
    const styles = fccMaxDownloadStyles(id, color, filter, 0.8);
    const subLayer = fccSublayerTemplate(id, layerConfigId, technology, filter, [...styles]);
    return [...styles, subLayer];
  });

  const layerConfig = fccLayerConfigTemplate(
    layerConfigId,
    groupId,
    label,
    [],
    technologies.map(({ id }) => id)
  );

  return [layerConfig, ...subLayers];
};

const groupConfigTemplate = (id: string, children: LayerConfig[], label: string): GroupConfig => ({
  ID: id,
  Type: "GROUP",
  Label: label,
  Key: null,
  Children: pickIDs(children),
  IsVisible: false,
  GlobalPosition: 0,
  Position: 0,
  ParentID: null,
  RootID: null,
});

const fccLayerConfigTemplate = (id: string, groupId: string | null, label: string, styles: LayerStyle[], children?: string[]): LayerConfig => ({
  ID: id,
  Label: label,
  IsVisible: true,
  Styles: pickIDs(styles),
  Position: 0,
  Type: "LAYER",
  GeometryType: "Polygon",
  GlobalPosition: 0,
  ParentID: groupId,
  Key: "census-tracts",
  MaxZoomLevel: 24,
  MinZoomLevel: 0,
  Children: children ?? [],
});

const fccSublayerTemplate = (
  id: string,
  layerId: string,
  label: string,
  filter: FilterConfiguration | null,
  styles: LayerStyle[]
): SublayerConfig => ({
  Type: "SUBLAYER",
  ParentID: layerId,
  FilterConfiguration: filter,
  Styles: pickIDs(styles),
  ID: id,
  Label: label,
  Key: "census-tracts",
  IsVisible: true,
  Position: 0,
  GeometryType: "Polygon",
});

const fccMaxDownloadStyles = (layerId: string, color: string, filter?: FilterConfiguration | null, opacity?: number): LayerStyle[] => [
  {
    ID: `${layerId}-polygon-fill`,
    Name: `${layerId}-polygon-fill`,
    GlobalPosition: 1,
    ConfigurationID: layerId,
    ConfigurationType: "LAYER",
    Position: 0,
    MapboxStyle: {
      type: "fill",
      filter: filter?.Mapbox ?? undefined,
      paint: {
        "fill-opacity": ["case", ["boolean", ["feature-state", "isEditing"], false], 0, opacity ?? 0.5],
        "fill-color": ["case", ["boolean", ["feature-state", "isSelected"], false], "#FFFF00", color],
      },
    },
    RawStyles: {
      Type: "fill",
      FillOpacity: opacity ?? 0.5,
      FillColor: color,
    },
    Type: "STYLE",
  },
];

const fccFiberServedStyles = (layerId: string, color: string): LayerStyle[] => [
  {
    ID: `${layerId}-polygon-fill`,
    Name: "fcc-fiber-served-polygon-fill",
    GlobalPosition: 1,
    ConfigurationID: layerId,
    ConfigurationType: "LAYER",
    Position: 0,
    MapboxStyle: {
      type: "fill",
      filter: ["==", ["get", "fiber_served"], "True"],
      paint: {
        "fill-opacity": ["case", ["boolean", ["feature-state", "isEditing"], false], 0, 0.3],
        "fill-color": ["case", ["boolean", ["feature-state", "isSelected"], false], "#FFFF00", color],
      },
    },
    RawStyles: {
      Type: "fill",
      FillOpacity: 0.5,
      FillColor: color,
    },
    Type: "STYLE",
  },
];
