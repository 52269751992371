import { FeatureCollection, MultiPolygon, Polygon } from "geojson";

import { apiSlice } from "fond/api/apiSlice";

type OverlayTileCollection = {
  MinZoom: number;
  MaxZoom: number;
  BoundingBox: Polygon | null;
};

export type Overlay = {
  ID: string;
  Name: string;
  Key: string;
  TileCollection: OverlayTileCollection | null;
};

type GetOverlaysResponse = {
  Overlays: Overlay[];
};

type GetOverlayFeaturesWithinResponse = {
  Collection: FeatureCollection;
};

export const overlaySlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getOverlays: build.query<Overlay[], string>({
      query: (AccountID) => ({
        url: "/v2/overlays",
        params: { account_id: AccountID },
      }),
      transformResponse: (response: GetOverlaysResponse) => response.Overlays,
    }),
    featuresWithinOverlay: build.mutation<FeatureCollection, { OverlayID: string; BoundingBox: Polygon | MultiPolygon; AccountID: string }>({
      query: ({ OverlayID, BoundingBox, AccountID }) => ({
        url: `/v2/overlays/${OverlayID}/features-within`,
        method: "POST",
        body: { bounding_box: BoundingBox },
        params: { account_id: AccountID },
      }),
      transformResponse: (response: GetOverlayFeaturesWithinResponse) => response.Collection,
    }),
  }),
});

export const { useGetOverlaysQuery, useFeaturesWithinOverlayMutation } = overlaySlice;
