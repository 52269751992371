import { fccMaxDownloadConfigs } from "fond/cityPlanner/fccConfiguration";
import { Configuration, ConfigurationUpsert, GroupConfig, MultiProject } from "fond/types";
import { LayerConfig, LayerStyle } from "fond/types/ProjectLayerConfig";
import { pickIDs } from "fond/utils";

/**
 * Generate the Configuration upsert based on the Multiproject Boundary & Areas.
 * A LayerConfig is generated for the Boundary & one for each Area.
 */
export const multiProjectConfiguration = (multiProject: MultiProject): ConfigurationUpsert => {
  const boundaryGroupId = "multiProjectBoundaryGroup";
  const areaGroupId = "multiProjectAreaGroup";
  const boundaryLayerId = multiProject.ID;

  const boundaryStyles = styleTemplate(boundaryLayerId, "#888");
  const boundaryLayer = layerConfigTemplate(boundaryLayerId, boundaryGroupId, "Boundary", [...boundaryStyles]);
  const boundaryGroup = groupConfigTemplate(boundaryGroupId, [boundaryLayer], "City Boundary");
  const areaStyles = multiProject.Areas.map((area) => styleTemplate(area.ID, area.Style.Color));
  const areaLayers = multiProject.Areas.map((area, index) =>
    layerConfigTemplate(area.ID, areaGroupId, area.Name || `Subarea ${index + 1}`, [...areaStyles[index]])
  );
  const areasGroup = groupConfigTemplate(areaGroupId, areaLayers, "Subareas");

  const fccUpsert = fccMaxDownloadConfigs();
  return [...boundaryStyles, ...areaStyles.flat(), boundaryLayer, ...areaLayers, areasGroup, boundaryGroup, ...fccUpsert];
};

/**
 * Takes the phases and generates the style configuration for the report build order map.
 */
export const generateBuildOrderMapConfiguration = (multiProject: MultiProject): Configuration => {
  const data = multiProjectConfiguration(multiProject);

  return {
    ID: "",
    Key: "",
    SourceID: "",
    Data: {
      ids: pickIDs(data),
      entities: Object.fromEntries(data.map((entity) => [entity.ID, entity])),
    },
    MapChildren: [...pickIDs(data.filter((entity) => entity.Type === "GROUP"))],
    Type: "MapLayerConfig",
  };
};

const groupConfigTemplate = (id: string, children: LayerConfig[], label: string): GroupConfig => ({
  ID: id,
  Type: "GROUP",
  Label: label,
  Key: null,
  Children: pickIDs(children),
  IsVisible: true,
  GlobalPosition: 0,
  Position: 0,
  ParentID: null,
  RootID: null,
});

const layerConfigTemplate = (id: string, groupId: string | null, label: string, styles: LayerStyle[]): LayerConfig => ({
  ID: id,
  Label: label,
  IsVisible: true,
  Styles: pickIDs(styles),
  Position: 0,
  Type: "LAYER",
  GeometryType: "Polygon",
  GlobalPosition: 0,
  ParentID: groupId,
  Key: "",
  MaxZoomLevel: 24,
  MinZoomLevel: 0,
  Children: [],
});

const styleTemplate = (layerId: string, color: string): LayerStyle[] => [
  {
    ID: `${layerId}-polygon-label`,
    Name: "multiProject-polygon-label",
    GlobalPosition: 2,
    ConfigurationID: layerId,
    ConfigurationType: "LAYER",
    Position: 0,
    MapboxStyle: {
      type: "symbol",
      filter: ["==", ["get", "boundaryId"], layerId],
      layout: {
        "text-field": ["get", "name"],
        "text-size": 12,
      },
      paint: {
        "text-opacity": ["case", ["boolean", ["feature-state", "isEditing"], false], 0, 1],
        "text-halo-width": 1.5,
        "text-halo-color": "#ffffff",
      },
    },
    RawStyles: {},
    Type: "STYLE",
  },
  {
    ID: `${layerId}-polygon-fill`,
    Name: "multiProject-polygon-fill",
    GlobalPosition: 1,
    ConfigurationID: layerId,
    ConfigurationType: "LAYER",
    Position: 0,
    MapboxStyle: {
      type: "fill",
      filter: ["==", ["get", "boundaryId"], layerId],
      paint: {
        "fill-opacity": ["case", ["boolean", ["feature-state", "isEditing"], false], 0, 0.3],
        "fill-color": ["case", ["boolean", ["feature-state", "isSelected"], false], "#FFFF00", color],
      },
    },
    RawStyles: {
      Type: "fill",
      FillOpacity: 0.2,
      FillColor: color,
    },
    Type: "STYLE",
  },
  {
    ID: `${layerId}-polygon-stroke`,
    Name: "multiProject-polygon-stroke",
    GlobalPosition: 1,
    ConfigurationID: layerId,
    ConfigurationType: "LAYER",
    Position: 0,
    MapboxStyle: {
      type: "line",
      filter: ["==", ["get", "boundaryId"], layerId],
      paint: {
        "line-dasharray": [2, 2],
        "line-width": 1.5,
        "line-opacity": ["case", ["boolean", ["feature-state", "isEditing"], false], 0, 1],
        "line-color": ["case", ["boolean", ["feature-state", "isSelected"], false], "#FFFF00", color],
      },
    },
    RawStyles: {
      Type: "line",
      LineOpacity: 1,
      LineColor: color,
      LineWidth: 2,
      LineDasharray: [2, 2],
    },
    Type: "STYLE",
  },
];
