import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { DrawUncombineEvent } from "@mapbox/mapbox-gl-draw";
import { Alert, Box, Button, FormHelperText, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import chroma from "chroma-js";
import { Feature, FeatureCollection, GeoJsonProperties, Geometry, MultiPolygon, Polygon } from "geojson";
import spatialTree from "geojson-rbush";
import { isEmpty } from "lodash";

import { LoadingButton } from "ui";

import { useGetMultiProjectQuery, useLazyGetBoundaryFeatureCountQuery, useUpdateMultiProjectMutation } from "fond/api";
import { CITY_PLANNER_AREA_MAX } from "fond/constants";
import DrawHandler from "fond/draw/DrawHandler";
import { intersect } from "fond/draw/helper";
import { HistoryContext, useHistoryContext } from "fond/history";
import { useOverlay } from "fond/hooks/useOverlay";
import { MapContext } from "fond/map/MapProvider";
import { polygonDrawingConfig } from "fond/map/Toolbar";
import * as turf from "fond/turf";
import { feature as toFeature, featureCollection, multiPolygon } from "fond/turf";
import { MultiProjectArea, MultiProjectAreaImportMethod, Store } from "fond/types";
import { isAnyPolygon, isPolygon } from "fond/types/geojson";
import { makeUuid } from "fond/utils";
import { layerPalette } from "fond/utils/colors";
import { generateUnusedName } from "fond/utils/naming";
import { formatNumber } from "fond/utils/number";
import { useStackedNavigationContext } from "fond/widgets";
import StackedNavigationHeader from "fond/widgets/StackedNavigation/StackedNavigationHeader";

import { AreaErrorType, clipOutside, setFeatureProperty, validateAreaFeatures } from "../helper";

import areaDrawStyles from "./areaDrawStyles";
import AreaList from "./AreaList";

export type SubareaProperties = {
  id: string;
  name: string;
  minCount: number | null;
  exactCount: number | null;
  importMethod: MultiProjectAreaImportMethod;
  color: string;
};

export type SubareaFeature = Feature<Polygon | MultiPolygon, SubareaProperties>;

const AreaDrawPanel: React.FC = () => {
  const multiProjectId = useSelector((state: Store) => state.project.projectId);
  const { data: multiProject } = useGetMultiProjectQuery(multiProjectId);
  const { goBack, clear } = useStackedNavigationContext();
  const { map, drawControl, setDrawMode } = useContext(MapContext);
  const { set: setHistory, state: historyState, clear: clearHistory } = useHistoryContext<FeatureCollection>(HistoryContext);
  const [updateMultiProject] = useUpdateMultiProjectMutation();
  const [getBoundaryFeatureCount, { isFetching }] = useLazyGetBoundaryFeatureCountQuery();
  const [listKey, setListKey] = useState(makeUuid());
  const [errors, setErrors] = useState<Record<string, AreaErrorType[]>>();
  const [autoCarving, setAutoCarving] = useState(false);
  const { featuresWithin } = useOverlay("census-block-groups-2024");

  /**
   * Get the multiproject Subareas & convert them to multiple polygons ready to load as
   * the initial draw features.
   */
  const initialFeatures = useMemo(() => {
    if (multiProject?.Areas && multiProject.Areas.length > 0) {
      return multiProject?.Areas.map((area) =>
        toFeature(
          area.Boundary,
          { id: area.ID, name: area.Name, importMethod: area.ImportMethod, exactCount: 0, minCount: null, color: area.Style.Color },
          { id: `${area.ID}` }
        )
      );
    } else if (multiProject?.Boundary) {
      const id = makeUuid();
      return [
        toFeature(
          multiProject.Boundary,
          { id: id, name: "Subarea", importMethod: "area_select_underground", exactCount: 0, minCount: null, color: layerPalette[0] },
          { id: id }
        ),
      ];
    }

    return [];
  }, [multiProject?.Areas, multiProject?.Boundary]);

  // Validate the multiproject areas
  const validate = useCallback(() => {
    const validationStatus = validateAreaFeatures(drawControl.current.getAll().features as SubareaFeature[]);
    setErrors(validationStatus);
  }, [drawControl]);

  /**
   * Callback function that is called when the user created a new feature
   * or updates existing features.
   */
  const updatePremCount = useCallback(
    async ({ features }: { features: Feature[] }) => {
      // Get the boundary information to be requested
      const boundaries = features.filter(isAnyPolygon).map(({ id, geometry: { type, coordinates } }) => ({
        id: id || "",
        geometry: { type, coordinates },
      }));
      try {
        const data = await getBoundaryFeatureCount({ boundaries: boundaries }).unwrap();
        if (data.FeatureCounts) {
          Object.keys(data.FeatureCounts).forEach((id) => {
            const { ExactCount, MinCount } = data.FeatureCounts[id].Addresses;
            setFeatureProperty(drawControl.current, id, "exactCount", ExactCount);
            setFeatureProperty(drawControl.current, id, "minCount", MinCount);
          });

          validate();

          // Refresh the list of subareas
          setListKey(makeUuid());
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    [drawControl, getBoundaryFeatureCount, validate]
  );

  /**
   * Sync the drawing features with the current state of history as the user
   * clicks undo/redo.
   */
  useEffect(() => {
    if (map && drawControl.current && map.hasControl(drawControl.current)) {
      if (isEmpty(historyState)) {
        drawControl.current.set(featureCollection(initialFeatures));
      } else {
        drawControl.current.set(historyState);
      }

      // Get the prem counts for the new feature
      updatePremCount({ features: drawControl.current.getAll().features });
    }
  }, [initialFeatures, map, drawControl, updatePremCount, historyState]);

  useEffect(() => {
    return () => {
      clearHistory();
      setDrawMode("no_feature");
    };
  }, [clearHistory, setDrawMode]);

  /**
   * Customised clipping function that clips the feature being drawn or updated so that it does not:
   * 1) Overlap any other subareas
   * 2) Extend beyond the city boundary
   */
  const clipFeature = useCallback(
    (feature: Feature<Polygon | MultiPolygon>, all: Feature[]): Feature<Polygon | MultiPolygon> => {
      // Don't allow feature to overlap other subareas
      let newFeature = intersect(feature, all);

      // Don't allow feature to extend beyond city boundary
      if (multiProject?.Boundary) {
        return clipOutside(newFeature, toFeature(multiProject.Boundary));
      }

      // No modification made
      return feature;
    },
    [multiProject?.Boundary]
  );

  /**
   * When the combine or uncombine event is fired we rename
   * the created features based on the original features & update feature counts.
   */
  const handleOnUncombine = useCallback(
    (event: DrawUncombineEvent) => {
      event.createdFeatures.forEach(({ id }, index) => {
        setFeatureProperty(drawControl.current, String(id), "id", id);
        if (index > 0 && id) {
          const newName = generateUnusedName(
            event.createdFeatures[0].properties?.name,
            drawControl.current.getAll().features.map((feat) => feat.properties?.name || "") || []
          );
          setFeatureProperty(drawControl.current, String(id), "name", newName);
        }
      });

      setHistory(drawControl.current.getAll());
    },
    [drawControl, setHistory]
  );

  const handleBack = () => {
    goBack();
  };

  const onReset = () => {
    drawControl.current.set(featureCollection(initialFeatures));
    clearHistory();
    // Get the prem counts for the new feature
    updatePremCount({ features: initialFeatures });
  };

  /**
   * Handles importing the currently draw features and updating the multiProject
   * with the new area boundaries.
   */
  const handleOnImport = () => {
    const importFeatures = drawControl.current.getAll().features.filter(isAnyPolygon) as Feature<Polygon | MultiPolygon, SubareaProperties>[];

    const areas: Partial<MultiProjectArea>[] = importFeatures.map((feat) => {
      const newFeature = isPolygon(feat) ? multiPolygon([feat.geometry.coordinates]).geometry : (feat.geometry as MultiPolygon);

      return {
        Name: feat.properties.name,
        Boundary: newFeature,
        ImportMethod: feat.properties.importMethod,
        Style: {
          Color: feat.properties.color,
        },
      };
    });

    updateMultiProject({ ID: multiProjectId, Areas: areas }).then(() => {
      setDrawMode("no_feature");
      clear();
    });
  };

  /**
   * Callback for when the form fields for an area are changed by the user
   */
  const handleOnAreaFieldsChange = (id: string) => {
    validate();
  };

  const getFeatureProperties = useCallback(
    (feature: Feature): GeoJsonProperties => {
      const color = layerPalette?.[drawControl.current.getAll().features.length - 1] || chroma.random().hex();

      return {
        id: String(feature.id),
        name: generateUnusedName("Subarea", drawControl.current.getAll().features.map((feat) => feat.properties?.name || "") || []),
        importMethod: "area_select_underground",
        minCount: null,
        exactCount: 0,
        color: color,
      };
    },
    [drawControl]
  );

  const handleAutoCarve = async () => {
    if (!multiProject?.Boundary || !featuresWithin) {
      return;
    }

    setAutoCarving(true);
    try {
      const polygons = await featuresWithin(multiProject.Boundary);
      if (polygons.features) {
        interface TreeNode {
          id: string;
          weight: number;
        }
        const featureMap = new Map(
          polygons.features.map((f) => {
            const id = makeUuid();
            return [
              id,
              toFeature(clipFeature(f as Feature<Polygon, TreeNode>, []).geometry, { id: id, weight: f.properties?.address_count ?? 0 }, { id }),
            ];
          })
        );

        const areas = Array.from(featureMap.values());
        const tree = spatialTree<Geometry, TreeNode>();
        tree.load(Array.from(featureMap.values()));

        for (let {
          properties: { id },
        } of areas.sort((f1, f2) => f1.properties.weight - f2.properties.weight)) {
          const area = featureMap.get(id);
          if (area === undefined) {
            continue;
          }
          const { weight } = area.properties;

          const bufferedArea = turf.buffer(area, 5, { units: "meters" }) as Feature<Geometry, TreeNode>;
          const nearbyAreas = tree.search(bufferedArea);

          let candidateAreas: { area: Feature<Geometry, TreeNode>; newWeight: number; overlap: number }[] = [];
          nearbyAreas.features.forEach((nearbyArea) => {
            const { id: nearbyAreaId } = nearbyArea.properties;
            if (nearbyAreaId === id) {
              return;
            }
            const nearbyAreaWeight = nearbyArea.properties.weight;
            if (weight + nearbyAreaWeight > 8000) {
              return;
            }
            const intersection = turf.intersect(turf.featureCollection([bufferedArea, nearbyArea]));
            const intersectionArea = intersection ? turf.area(intersection) : 0;
            candidateAreas.push({ area: nearbyArea, newWeight: weight + nearbyAreaWeight, overlap: intersectionArea });
          });

          candidateAreas.sort(({ overlap: overlap1 }, { overlap: overlap2 }) => overlap2 - overlap1);
          if (!candidateAreas.length) {
            continue;
          }

          // Otherwise combine this area with its neighbour
          const { area: nearbyArea, newWeight } = candidateAreas[0];
          const nearbyAreaId = nearbyArea.properties.id;
          const union = turf.union(area, nearbyArea).geometry;
          const combinedArea = toFeature<Geometry, TreeNode>(union, { id: nearbyAreaId, weight: newWeight }, { id: nearbyAreaId });

          featureMap.delete(id);
          featureMap.delete(nearbyAreaId);
          featureMap.set(nearbyAreaId, combinedArea);
          tree.remove(area);
          tree.remove(nearbyArea);
          tree.insert(combinedArea);
        }

        const finalFeatures = Array.from(featureMap.values()).map((f, i) => {
          const id = makeUuid();
          return toFeature(
            f.geometry,
            {
              id: id,
              name: `Subarea (${i + 1})`,
              importMethod: "area_select_underground",
              exactCount: f.properties.weight,
              minCount: null,
              color: layerPalette[i % layerPalette.length],
            },
            { id: id }
          );
        });

        drawControl.current.set(featureCollection(finalFeatures));
        updatePremCount({ features: finalFeatures });
      }
    } finally {
      setAutoCarving(false);
    }
  };

  return (
    <>
      <DrawHandler
        initialFeatures={initialFeatures}
        source="multiProject-source"
        styles={areaDrawStyles}
        autoClip
        clipFeature={clipFeature}
        config={polygonDrawingConfig}
        getFeatureProperties={getFeatureProperties}
        onUncombine={handleOnUncombine}
      />

      <StackedNavigationHeader onBackButtonClick={handleBack} title="Draw on map" />
      {multiProject?.Boundary && featuresWithin && (
        <>
          <Typography fontWeight="500">Auto Carve</Typography>
          <Box data-testid="draw-panel" sx={{ py: 2 }}>
            <Alert severity="info" data-testid="auto-carve-alert">
              FOND can automatically carve subareas based on your boundary. You have the flexibility to edit, manage, and delete these areas to suit
              your needs.
              <br />
              <br />
              Alternatively, you can manually add subareas using the drawing tools below.
            </Alert>
            <LoadingButton loading={autoCarving} fullWidth variant="contained" sx={{ my: 2 }} disabled={autoCarving} onClick={handleAutoCarve}>
              Carve Subareas
            </LoadingButton>
          </Box>
        </>
      )}
      <Typography fontWeight="500">Draw Tool</Typography>
      <Box data-testid="draw-panel" sx={{ py: 2 }}>
        <Alert severity="info" data-testid="layer-alert">
          Draw the subareas using the draw polygon tool. You can also split or carve the boundary to your desired number of subareas.
          <br />
          <br />
          Note: subareas must not exceed {formatNumber(CITY_PLANNER_AREA_MAX)} prems
        </Alert>

        <AreaList key={listKey} onReset={onReset} onChange={handleOnAreaFieldsChange} errors={errors} />

        <Box display="flex" alignItems="center" justifyContent="space-between" mt={2}>
          <Box>{errors && <FormHelperText error>Invalid areas found.</FormHelperText>}</Box>
          <Box>
            <Button color="primary" size="small" onClick={handleBack}>
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={handleOnImport}
              sx={{ ml: 1, px: 2 }}
              data-testid="finish-button"
              disabled={isFetching || errors !== undefined}
            >
              Finish
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AreaDrawPanel;
