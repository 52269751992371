import { useMemo } from "react";
import { useSelector } from "react-redux";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { FeatureCollection, MultiPolygon, Polygon } from "geojson";

import { fondServiceURL, selectCurrentAccount } from "fond/api";
import { Overlay, useFeaturesWithinOverlayMutation, useGetOverlaysQuery } from "fond/api/overlaySlice";

interface UseOverlayReturns {
  overlay: Overlay | undefined;
  tileUrl: string | undefined;
  isLoading: boolean;
  featuresWithin: ((boundingBox: Polygon | MultiPolygon) => Promise<FeatureCollection>) | undefined;
}

export const useOverlay = (key: string): UseOverlayReturns => {
  const currentAccount = useSelector(selectCurrentAccount);
  const { data: overlays, isLoading } = useGetOverlaysQuery(currentAccount?.ID ?? skipToken);
  const overlay = useMemo(() => overlays?.find((o) => o.Key === key), [overlays, key]);
  const [fetchFeaturesWithin] = useFeaturesWithinOverlayMutation();

  const tileUrl = useMemo(() => {
    if (!currentAccount || !overlay) {
      return undefined;
    }
    return `${fondServiceURL}/v2/overlays/${overlay.ID}/tiles/{z}/{x}/{y}?account_id=${currentAccount.ID}`;
  }, [currentAccount, overlay]);

  const featuresWithin = useMemo(() => {
    if (!overlay || !currentAccount) {
      return undefined;
    }
    return (boundingBox: Polygon | MultiPolygon) =>
      fetchFeaturesWithin({ OverlayID: overlay.ID, BoundingBox: boundingBox, AccountID: currentAccount.ID }).unwrap();
  }, [overlay, fetchFeaturesWithin]);

  return { overlay, tileUrl, isLoading, featuresWithin };
};
