import * as React from "react";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePrevious } from "react-use";
import { Box } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import * as Sentry from "@sentry/react";
import { uniqWith } from "lodash";
import mapboxgl, { FeatureIdentifier, GeoJSONSource, Map as BaseMap, ScaleControl, VectorSource } from "mapbox-gl";

import { fondServiceURL, headersCreator, useGetIconsQuery, useGetVersionStatusQuery } from "fond/api";
import { MAP_ICON_HEIGHT, MAP_ICON_WIDTH } from "fond/constants";
import { usePermissionCheck } from "fond/hooks/usePermissionCheck";
import { LayerIds } from "fond/layers";
import { LayoutContext } from "fond/layout/LayoutProvider";
import { selectWidget } from "fond/layout/widgets";
import FeatureEditor from "fond/map/FeatureEditor";
import FeatureHandler from "fond/map/FeatureHandler";
import { MapContext } from "fond/map/MapProvider";
import Ruler from "fond/map/Ruler";
import { getMapStyle } from "fond/map/styles";
import mixpanel from "fond/mixpanel";
import { getCurrentProject, getCurrentProjectHighlights } from "fond/project";
import { RegenerateBomBanner } from "fond/project/bom/RegenerateBomBanner";
import DesignNeedsUpdating from "fond/project/DesignNeedsUpdating";
import { showUpdateDesignBanner } from "fond/project/helpers";
import { openFeatureSelectionPopup, selectFeature, unselectComment, unselectFeature, updateProjectView } from "fond/project/redux";
import { selectComment } from "fond/redux/comments";
import store from "fond/store";
import * as turf from "fond/turf";
import { AppThunkDispatch, EditMode, ErrorEventProps, Project, Store, View, ViewCamera, ViewLocation, Widget } from "fond/types";
import { LayerConfig, LayerStyle, SublayerConfig } from "fond/types/ProjectLayerConfig";
import { isValidBoundingBox } from "fond/utils";
import { Actions } from "fond/utils/permissions";

import { AsyncOperationState } from "../async/redux";

import Footer from "./Footer";
import MapContent from "./MapContent";
import { MapListener } from "./MapListener";
import { loadMap, unloadMap } from "./redux";
import { Toolbar } from "./Toolbar";

import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "./Map.scss";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY || "";
type SystemOfMeasurement = "imperial" | "metric";
type BBox = [[number, number], [number, number]];

/*
 * This function sets the following transformation request changes:
 * * Disabled the sprite sheet cache.
 * * Sets the authorization header on all requests to the FOND service API.
 *
 * NOTE: The transformations must be re-set when the access token becomes invalid.
 *
 * This is achieved by setting the transformRequest function on the maps request manager.
 * https://docs.mapbox.com/mapbox-gl-js/api/map/#map-parameters
 */
export const setRequestTransformations = async (map: BaseMap): Promise<BaseMap | undefined> => {
  // Disable ESLint for the request manager no-underscore-dangle access
  /* eslint-disable no-underscore-dangle */

  // TODO: Use the setTransformRequest setter after mapbox upgrade (v2).
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const requestManager = map._requestManager;
  if (requestManager == null) {
    return;
  }

  // Convert the Headers instance to a json object that can be used by transformRequest eg. {authorization: "Bearer eyJraZ..."}
  const authHeaders = await headersCreator.createHeaders();
  const authHeadersObj = Object.fromEntries(authHeaders?.entries() || {});

  requestManager._transformRequestFn = (url: string, resourceType: string) => {
    // DEVEX-6305: Add authorization headers to all FOND service requests.
    if (url.startsWith(fondServiceURL)) {
      return { url: url, headers: authHeadersObj };
    }

    /*
     * DEVEX-6655: disable the sprite cache due to synchronization issues between the spritesheet and sprite coordinates.
     * see: https://github.com/mapbox/mapbox-gl-native/issues/4117
     */
    if (["SpriteJSON", "SpriteImage"].includes(resourceType)) {
      let noCacheUrl = new URL(url);
      // The 'fresh' parameter is a mapbox setting that informs the mapbox server to set the cache-control response header to no-cache.
      noCacheUrl.searchParams.append("fresh", "true");
      return { url: noCacheUrl.toString() };
    }

    return null;
  };
  /* eslint-enable */
};

/*
 * Refresh a tile source by invalidating the cache, clearing the tiles and triggering a re-render.
 * Cache invalidation is performed by adding a timestamped url parameter to the vector tile source url.
 */
export const refreshTileSource = ({ map, sourceId, source }: { map: BaseMap; sourceId: string; source: VectorSource }): void => {
  if (source.type !== "vector") {
    Sentry.captureMessage(`Attempted to refresh the vector tiles of a ${source.type} source`);
    return;
  }

  // Set the timestamp on the tileUrl to trick the cache into refreshing.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  map.getSource(sourceId).tiles = source.tiles?.map((tileUrl) => {
    // Update the `dt` query parameter (may have been set by the previous invalidation)
    let newTileUrl = new URL(tileUrl);
    newTileUrl.searchParams.set("dt", Date.now().toString());
    return `${newTileUrl}`;
  });
  // Clear all existing tiles.
  // DEVEX-7249 to remove the accessing of private variables relating to style source caches
  // @ts-expect-error: style is not typed on `Map`
  map.style.sourceCaches?.[sourceId].clearTiles();
  // Trigger a re-render of the vector tile source
  // @ts-expect-error: style and transform is not typed on `Map`
  map.style.sourceCaches?.[sourceId].update(map.transform);
};

export const MapCreator = {
  /**
   * We have `MapCreator.createMap` rather than just `createMap` so we can
   * switch out `createMap` in tests.
   */
  createMap: async ({
    container,
    view,
    bbox,
    attributionControl,
    ...options
  }: {
    container: HTMLDivElement;
    view?: ViewCamera | null;
    bbox?: BBox;
    style?: string;
    attributionControl?: boolean;
  }): Promise<BaseMap> => {
    const map = await new mapboxgl.Map({
      container: container,
      bounds: bbox,
      attributionControl: attributionControl,
      ...view,
      ...options,
    });
    await setRequestTransformations(map);
    return map;
  },
};

const useCustomStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      "& .mapboxgl-ctrl.mapboxgl-ctrl-attrib": {
        backgroundColor: "transparent",
        "& .mapboxgl-ctrl-attrib-button": {
          display: "none",
        },
      },
      "& .mapboxgl-ctrl-bottom-right": {
        marginBottom: theme.spacing(3),
        marginRight: theme.spacing(1),
      },
      "& .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-attrib": {
        fontSize: 11,
      },
      "& .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-scale": {
        position: "absolute",
        bottom: -30,
        right: 150,
        height: 15,
        display: "flex",
        alignItems: "center",
        backgroundColor: "transparent",
        borderColor: "rgb(232, 234, 237)",
        borderWidth: 1,
        color: "rgb(232, 234, 237)",
      },
    },
    footer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: `${theme.palette.background.sidebar.primary}a6`,
      color: "rgb(232, 234, 237)",
      fontSize: 12,
      height: theme.spacing(3),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    coords: {
      cursor: "pointer",
    },
    popover: {
      backgroundColor: theme.palette.background.sidebar.primary,
      color: theme.palette.common.white,
      fontSize: 11,
    },
  })
);

interface IProps {
  children?: React.ReactNode | React.ReactNode[];
  editMode: EditMode;
  layerConfigs?: Array<LayerConfig | SublayerConfig>;
  styles?: LayerStyle[];
  /**
   * A flat list of layers & sublayers indicating their current view status
   */
  layerView: { [key: string]: boolean };
}

/**
 * A wrapper component for a Mapbox GL map. Instantiates a map object
 * and makes it available to all children. Will render children only once the
 * map is loaded.
 */
const Map: React.FC<IProps> = ({ children, editMode, layerConfigs, styles, layerView }: IProps) => {
  const classes = useCustomStyles();
  const dispatch: AppThunkDispatch = useDispatch();
  const { map, setMap, userPosition, drawControl, mapStyle: style } = useContext(MapContext);
  const layout = useContext(LayoutContext);
  const containerRef = useRef<HTMLDivElement>(null);
  const [distance, setDistance] = useState<string | undefined>();
  const project = useSelector((state: Store): Project => getCurrentProject(state.project));
  const versionId = useSelector((state: Store) => state.project.versionId);
  const { data: versionStatus } = useGetVersionStatusQuery(versionId, { skip: !versionId });
  const selectedComment = useSelector((state: Store) => state.project.selectedComment);
  const selectedFeature = useSelector((state: Store) => state.project.selectedFeature);
  const featureSelectionPopup = useSelector((state: Store) => state.project.featureSelectionPopup);
  const { data: allIcons } = useGetIconsQuery(undefined);
  const highlightedFeatures: FeatureIdentifier[] = useSelector((state: Store) => getCurrentProjectHighlights(state.project));
  const editingLayerGroupId = useSelector((state: Store) => state.project.editingLayerGroupId);
  const showDesignBanner = project && versionStatus ? showUpdateDesignBanner(project, versionStatus) : false;
  const canEdit = usePermissionCheck(Actions.PROJECT_EDIT, project.Permission.Level);
  const loadDataStatus = useSelector((state: Store) => state.project.loadDataStatus);

  const bomNeedsUpdating = project != null && versionStatus?.BomHasChanged === true;
  const previousHighlights: FeatureIdentifier[] = usePrevious(highlightedFeatures) || [];
  const previousUnits: SystemOfMeasurement = usePrevious(project.SystemOfMeasurement) || "imperial";
  let scaleControl = useRef<ScaleControl>();

  /**
   * We only access the view.location value on mount as we don't
   * want to trigger a component render every time the map changes
   * zoom or positioning.
   *
   * We ignore any changes to view.location after mount.
   */
  let location: ViewLocation | undefined;
  useEffect(() => {
    location = store.getState().project.projects[project.ID]?.view.location;
  }, []);

  let mapListener: MapListener | null = null;

  useEffect(() => {
    return () => {
      setMap(undefined);
      mapListener = null;
      dispatch(unloadMap());
    };
  }, []);

  /**
   * Once the container is rendered create the Map
   */
  useEffect(() => {
    async function makeNewMap() {
      // Once the container is rendered create the Map
      if (containerRef.current) {
        let bbox: BBox | undefined;
        if (
          location?.camera == null &&
          location?.bbox != null &&
          // Be careful we don't crash Mapbox by passing it out-of-range coordinates.
          isValidBoundingBox(location.bbox)
        ) {
          bbox = location.bbox;
        }
        const baseStyle = getMapStyle(style);
        const newMap = await MapCreator.createMap({
          container: containerRef.current,
          view: location?.camera,
          bbox: bbox,
          style: baseStyle.url,
        });
        // As of MAG-758 we have decided we don't want these features, especially
        // as touchpad users can activate them by mistake and now there is no way
        // to revert.
        newMap.dragRotate.disable();
        newMap.touchZoomRotate.disableRotation();
        newMap.on("load", () => {
          setMap(newMap);
          // Our Selenium tests assume that the Map instance is available at `window.map`.
          window.map = newMap;
        });

        newMap.on("error", async ({ error, source, sourceId }: ErrorEventProps) => {
          // Refresh the token and tiles if this is a vector tile authorisation error
          if (source?.type === "vector" && error?.status && [400, 401].includes(error.status)) {
            await setRequestTransformations(newMap);
            refreshTileSource({ map: newMap, sourceId: sourceId, source: source });
          }
        });
      }
    }

    // We need to create the new async function makeNewMap and call it here,
    // because we are awaiting the function MapCreator.createMap
    // otherwise we get a warning like
    // "It looks like you wrote useEffect(async () => ...) or returned a Promise.
    // Instead, write the async function inside your effect and call it immediately:"
    makeNewMap();
  }, [containerRef]);

  /**
   * Handles the monitoring of the highlighted features
   */
  useEffect(() => {
    // Unhighlighted the previous highlighted features
    if (previousHighlights.length) {
      setFeatureState?.({ features: previousHighlights, state: { isSelected: false } });
    }

    // Highlight the newly selected features
    if (highlightedFeatures.length) {
      setFeatureState?.({ features: highlightedFeatures, state: { isSelected: true } });
    }
  }, [highlightedFeatures]);

  /**
   * Monitor the map for initialisation
   */
  useEffect(() => {
    // Add user position marker after the map is loaded
    const addSourceForUserPosition = () => {
      map?.addSource("location-point", {
        type: "geojson",
        data: {
          type: "Feature",
          properties: {},
          geometry: {
            type: "Point",
            coordinates: [],
          },
        },
      });
      map?.addSource("location-area", {
        type: "geojson",
        data: turf.circle([0, 0], 1, {}),
      });
    };

    if (map) {
      dispatch(loadMap(map));
      mapListener = new MapListener(map, handleViewChange);

      if (!map.getSource("location-point") && !map.getSource("location-area")) {
        addSourceForUserPosition();
      }

      map.on("styledataloading", () => {
        map.once("styledata", () => {
          addSourceForUserPosition();
        });
      });

      // Once map has loaded we can add any required controls
      setupScaleControl(project.SystemOfMeasurement);

      map.on("styleimagemissing", addImage);
    }

    return () => {
      map?.off("styledataloading", addSourceForUserPosition);
    };
  }, [map]);

  /**
   * Monitor for System of Measurement changes & re-add the control
   * to the map on change to make sure we are in the correct measurement unit.
   */
  useEffect(() => {
    if (map && project.SystemOfMeasurement !== previousUnits) {
      setupScaleControl(project.SystemOfMeasurement);
    }
  }, [project.SystemOfMeasurement]);

  const onLoad = (iconId: string) => (ev: Event) => {
    if (ev?.target) {
      map?.addImage(iconId, ev.target as HTMLImageElement);
    }
  };

  const addImage = ({ id }: any) => {
    // Attempt to add a missing image
    const icon = allIcons?.find((i) => i.Name === id);
    if (icon) {
      const image = new Image(MAP_ICON_WIDTH, MAP_ICON_HEIGHT);
      image.onload = onLoad(id);
      image.src = `data:image/png;base64, ${icon.GeneratedPng}`;
    }
  };

  /**
   * Given a Mapbox mouse event, return a FOND feature (as opposed to a base
   * Mapbox feature like a landmark, which we don't let users interact with)
   * if there is one within 5 pixels of the event, otherwise return null.
   */
  const getFeature = (event: mapboxgl.MapMouseEvent) => {
    const queryBbox: [mapboxgl.PointLike, mapboxgl.PointLike] = [
      [event.point.x - 5, event.point.y - 5],
      [event.point.x + 5, event.point.y + 5],
    ];

    const features = map?.queryRenderedFeatures(queryBbox);
    // We rely on FOND features having a "layerId" property,
    // to differentiate them from built-in Mapbox GL features (which we don't want popups
    // for). We also don't want a popup for the area select polygon.
    return features?.find(
      (feature: mapboxgl.MapboxGeoJSONFeature) =>
        (feature.properties?.layerId != null && feature.layer.id !== "polygon") || feature.properties?.commentID
    );
  };

  /**
   * Handles setting the features states
   * For example a featureState { isSelected: true } will hightlight the features
   * (based on mapbox styles)
   */
  const setFeatureState = useCallback(
    ({ features, state }: { features: FeatureIdentifier[] | mapboxgl.MapboxGeoJSONFeature[]; state: any }) => {
      features.forEach((feature) => {
        map?.setFeatureState(
          {
            source: feature.source,
            sourceLayer: feature.sourceLayer,
            id: feature.id,
          },
          state
        );
      });
    },
    [map]
  );

  /**
   * Handles the mouse move event for the map.
   * Callback is used to update the function when map or editMode changes
   */
  const handleOnMouseMove = useCallback(
    (event: mapboxgl.MapMouseEvent) => {
      if (editMode === "edit") {
        // We have handlers for edit mode elsewhere.
        return;
      }

      // Give the user a cursor to show when a relevant feature is clickable.
      if (editMode !== "measure") {
        if (map) {
          map.getCanvas().style.cursor = getFeature(event) != null ? "pointer" : "";
        }
      } else if (map) {
        // When in measure mode we set the cursor to "" & mapboxDraw will
        // handle the cursor
        map.getCanvas().style.cursor = "";
      }
    },
    [map, editMode]
  );

  /**
   * Handles the click event for the map.
   * Callback is used to update the function when map or editMode changes
   */
  const handleOnMapClick = useCallback(
    (event: mapboxgl.MapMouseEvent) => {
      if (["polygonSelect", "edit", "comment", "measure", "styles"].includes(editMode)) {
        // We have handlers for edit mode elsewhere, and we don't want
        // feature popups when we're doing polygon select.
        return;
      }

      // Locate features within a certain boundary of where the user clicked
      const queryBbox: [mapboxgl.PointLike, mapboxgl.PointLike] = [
        [event.point.x - 5, event.point.y - 5],
        [event.point.x + 5, event.point.y + 5],
      ];

      // Get all unique features within the boundary
      const features = uniqWith(
        map
          ?.queryRenderedFeatures(queryBbox)
          .filter(
            (feature: mapboxgl.MapboxGeoJSONFeature) =>
              (feature.properties?.layerId != null || feature.properties?.commentID != null) && feature.layer.id !== "polygon"
          ),
        /**
         * We filter out duplicate features that can exist when a feature has multiple styles.
         *
         * Exception: The output/service_location layer allows for duplication
         * as we treat it and CostToServe styles as different layers even though they all point to the same features.
         */
        (valueA, valueB) => valueA.source !== LayerIds.serviceLocation && valueA.id === valueB.id
      ).sort((valueA, valueB) => (valueA.source === valueB.source ? 0 : 1));

      if (features.length === 1) {
        mixpanel.track("Opened feature popup");
        handleOnSelectFeature(features[0], event.lngLat);
      } else if (features.length > 1) {
        mixpanel.track("Opened features selection popup");
        // We only present to the user a maximum of 25 features to select from
        onFeaturesClick(features.slice(0, 25), event.lngLat);
      } else {
        onPopupClose();
      }
    },
    [map, editMode, selectedFeature, selectedComment, featureSelectionPopup]
  );

  // We rebind the map events when values required inside the event listeners change
  // This is required due to the way mapbox binds events & closure prevent values updating
  useEffect(() => {
    map?.on("click", handleOnMapClick);
    map?.on("mousemove", handleOnMouseMove);
    return () => {
      map?.off("click", handleOnMapClick);
      map?.off("mousemove", handleOnMouseMove);
    };
  }, [handleOnMapClick, handleOnMouseMove]);

  const handleOnSelectFeature = (feature: mapboxgl.MapboxGeoJSONFeature, lngLat: mapboxgl.LngLatLike) => {
    if (feature.properties?.commentID) {
      dispatch(selectComment({ commentID: feature.properties?.commentID, features: [feature], point: lngLat }));
    } else if (feature.properties?.layerId) {
      dispatch(selectFeature(feature, lngLat));
      if (layout.model) selectWidget(layout.model, Widget.Properties);
    }
  };

  /**
   * Presents the co-located features within a Mapbox Popup for the user to select from
   */
  const onFeaturesClick = (features: mapboxgl.MapboxGeoJSONFeature[], lngLat: mapboxgl.LngLatLike) =>
    dispatch(openFeatureSelectionPopup(features, lngLat));

  /**
   * Closes the Feature Popup and unselects it
   */
  const onPopupClose = useCallback(() => {
    if (selectedFeature || featureSelectionPopup) {
      dispatch(unselectFeature());
    } else if (selectedComment) {
      dispatch(unselectComment());
    }
  }, [selectedFeature, featureSelectionPopup, selectedComment]);

  /**
   * Adds & Removes the scale control to the map based on the current
   * System of Measurements
   */
  const setupScaleControl = (unit: SystemOfMeasurement) => {
    if (map) {
      if (scaleControl.current != null && map.hasControl(scaleControl.current)) {
        map.removeControl(scaleControl.current);
      }

      const newScale = new ScaleControl({ maxWidth: 500, unit: unit });
      map.addControl(newScale, "bottom-right");
      scaleControl.current = newScale;
    }
  };

  /**
   * Handles the changing of the map view
   */
  const handleViewChange = (newView: View) => {
    dispatch(updateProjectView(project.ID, newView));
  };

  useEffect(() => {
    if (map && userPosition) {
      const newPointSourceData = {
        type: "Feature",
        properties: {},
        geometry: {
          type: "Point",
          coordinates: userPosition.center,
        },
      };
      (map.getSource("location-point") as GeoJSONSource)?.setData(newPointSourceData as mapboxgl.MapboxGeoJSONFeature);
      (map.getSource("location-area") as GeoJSONSource)?.setData(
        turf.circle(userPosition.center as number[], userPosition.accuracy, { units: "kilometers" })
      );
      map.addLayer({
        id: "user-location-pin",
        type: "circle",
        source: "location-point",
        paint: {
          "circle-color": "#4264fb",
          "circle-radius": 8,
          "circle-stroke-width": 2,
          "circle-stroke-color": "#ffffff",
        },
      });
      map.addLayer({ id: "user-location-area", type: "fill", source: "location-area", paint: { "fill-color": "#4264fb", "fill-opacity": 0.2 } });
    }

    if (!userPosition && map?.getLayer("user-location-pin")) {
      map?.removeLayer("user-location-pin");
    }

    if (!userPosition && map?.getLayer("user-location-area")) {
      map?.removeLayer("user-location-area");
    }
  }, [userPosition]);

  const setDrawControl = (newDrawControl: any) => {
    drawControl.current = newDrawControl;
  };

  return (
    <Box ref={containerRef} className={classes.root} data-testid="map-container" id="mapContainer">
      {editMode !== "styles" && canEdit && (
        <>
          {showDesignBanner && <DesignNeedsUpdating />}
          {!showDesignBanner && bomNeedsUpdating && versionStatus?.HasSolution && <RegenerateBomBanner />}
        </>
      )}

      {map && layerConfigs && (
        <>
          {children}
          <MapContent editMode={editMode} layerConfigs={layerConfigs} layerView={layerView} styles={styles} />
          <Footer distance={distance} loading={loadDataStatus === AsyncOperationState.executing} />
          <Ruler onChange={setDistance} />
          <FeatureHandler editMode={editMode} layerConfigs={layerConfigs} onClose={onPopupClose} />

          {editMode === "edit" && project.SubType === "planner" && (
            <FeatureEditor map={map} inputLayerGroupId={editingLayerGroupId} setDrawControl={setDrawControl} />
          )}

          {editMode === "edit" && project.SubType === "design" && <Toolbar />}
        </>
      )}
    </Box>
  );
};

export default Map;
